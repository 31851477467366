<script lang="ts" setup>
import {CityResource} from '@/resources/PageResource'

const props = defineProps<{
  cities: CityResource[]
}>()

const settings = {
  itemsToShow: 1.5,
  snapAlign: 'center',
}

const breakpoints = {
  480: {
    itemsToShow: 2,
    snapAlign: 'center',
  },
  700: {
    itemsToShow: 3.5,
    snapAlign: 'center',
  },
  1024: {
    itemsToShow: 5,
    snapAlign: 'start',
  },
  1440: {
    itemsToShow: 6,
    snapAlign: 'start',
  },
  1680: {
    itemsToShow: 8,
    snapAlign: 'start',
  },
  1920: {
    itemsToShow: 9,
    snapAlign: 'start',
  }
}
</script>

<template>
  <div class="carousel">
    <Carousel v-bind="settings" :breakpoints="breakpoints" :wrap-around="true">
      <Slide v-for="city in cities" :key="city.name">
        <div class="carousel__item">
          <NuxtLink :href="city.url">
            <figure>
              <NuxtImg :src="city.image_url" :alt="city.image_alt" width="300"/>
              <figcaption>
                {{ city.name }}
              </figcaption>
            </figure>
          </NuxtLink>
        </div>
      </Slide>

      <template #addons>
        <Navigation/>
      </template>
    </Carousel>
  </div>
</template>

<style lang="postcss">
.carousel__item {
  display: flex;
  align-items: center;
  gap: var(--container-gap);
  white-space: nowrap;
  will-change: transform;

  & a {
    text-decoration: none;
  }

  & figure {
    margin: 0;
    width: min(200px, 56vw);
    aspect-ratio: 1 / 1;

    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: var(--border-radius-big);
    }

    & figcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      margin-top: .5rem;
      font-weight: 700;
      color: var(--text-color-secondary);
    }
  }

}
</style>
