<script setup lang="ts">
import { DetailResponse } from '@eli5/js-helpers'
import { notifySentry } from '@/helpers/notifySentry'
import { LocationCollectionResource } from '~/resources/LocationCollectionResource'
import { PageResource, PageSectionResource } from '~/resources/PageResource'
const runtimeConfig = useRuntimeConfig()

const { locale } = useI18n()
const Resource = DetailResponse.extend({
  data: PageResource,
})
const { data } = await useFetch(`/pages/${locale.value}/home`, {
  method: 'GET',
  baseURL: runtimeConfig.public.apiBase,
  transform: (data) => {
    try {
      return Resource.parse(data).data
    } catch (e) {
      notifySentry(e)
    }
  },
})
useSeoMeta({
  title: () => `${data.value?.title ?? ''}`,
})
defineOgImage({
  title: `${data.value?.title ?? ''}`,
  backgroundColor: 'bg-green-500',
})

const localePath = useLocalePath()

const isAccordionOpen = ref<string | null>(null)
const toggleAccordion = (id: string) =>
  (isAccordionOpen.value =
    isAccordionOpen.value === id ? (isAccordionOpen.value = null) : id)

const tabs: PageSectionResource[] = [
  ...(data.value?.sections ?? []).slice(1, 4),
]

const MIDDLE_OF_THE_NETHERLANDS = '52.2129919,5.2793703'
const { data: locations } = await useFetch(`locations`, {
  method: 'GET',
  baseURL: runtimeConfig.public.apiBase,
  query: { per_page: 100 },
  server: false,
  transform: (data) => {
    try {
      return LocationCollectionResource.parse(data).data
    } catch (e) {
      notifySentry(e)
    }
  },
})
</script>

<template>
  <div class="page-wrapper" v-if="data">
    <HomeHeader :section="data.sections.at(0)" />
    <section class="locations-wrapper">
      <CityCarousel :cities="data.sections.find((s) => s.slug === 'home-cities')?.additional" />
      <header class="container">
        <strong>
          <div v-html="data.sections.at(0)?.content ?? ''"></div>
        </strong>
        <NuxtLink :to="localePath('/spaces')" class="button-link primary">{{
          data.sections.at(0)?.buttons?.['button-1'] ?? ''
        }}</NuxtLink>
      </header>
    </section>

    <section class="container reviews">
      <header>
        <h2>{{ data.sections.at(4)?.title ?? '' }}</h2>
        <br />
        <ClientOnly>
          <!-- TrustBox widget - Review Collector -->
          <div ref="trustbox" id="trustboxFooter" class="trustpilot-widget" data-locale="nl-NL"
               data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6571e58b35c51e659f727064"
               data-style-height="52px" data-style-width="100%" style="height: 40px; align-self: end;">
            <a href="https://nl.trustpilot.com/review/worksnap.net" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </ClientOnly>
      </header>
      <ReviewCard
        v-for="review in data.sections.at(4)?.additional ?? []"
        :review="review"
      />
    </section>

    <section class="container locations">
      <ClientOnly fallback-tag="div" fallback="Loading map...">
        <GoogleMaps
          :locations="locations ?? []"
          :center="MIDDLE_OF_THE_NETHERLANDS"
          :zoomLevel="8"
        />
      </ClientOnly>
    </section>

    <section class="container faq">
      <header>
        <h2>{{ data.sections.at(5)?.title ?? '' }}</h2>
      </header>
      <template v-for="faq in data.sections.at(5)?.additional ?? []">
        <DefaultAccordion
          v-if="'question' in faq && 'answer' in faq"
          :title="faq.question"
          :id="faq.question"
          :isOpen="isAccordionOpen === faq.question"
          @toggle="toggleAccordion"
        >
          <div class="faq-answer" v-html="faq.answer" />
        </DefaultAccordion>
      </template>
      <i18n-t keypath="home.faq.content" tag="p" scope="global" >
        <template v-slot:help>
          <NuxtLink :to="localePath('/faq')">{{ $t('home.faq.help') }}</NuxtLink>
        </template>
      </i18n-t>
    </section>
    <CallToAction :background="data.sections.at(6)?.images?.at(0)?.url ?? ''">
      <h2>{{ data.sections.at(6)?.title ?? '' }}</h2>
      <div v-html="data.sections.at(6)?.content ?? ''"></div>
      <NuxtLink :to="localePath('/spaces')" class="button-link primary">{{
        data.sections.at(6)?.buttons?.['button-1'] ?? ''
      }}</NuxtLink>
    </CallToAction>
  </div>
  <ErrorPage v-else></ErrorPage>
</template>

<style lang="postcss" scoped>
@import 'open-props/media';
.page-wrapper {
  & section {
    margin-block: var(--container-block-padding);
    & h2 {
      margin: 0;
    }
    &.locations-wrapper {
      overflow: hidden;
      & header {
        --container-width: 77rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--container-gap);
        /* margin-block: var(--container-block-padding); */
        text-align: center;
        & strong {
          color: var(--text-color-secondary);
          * {
            @media (--md-n-below) {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
    &.reviews {
      --columns: 4;
      display: grid;
      grid-template-columns: repeat(
        auto-fit,
        minmax(calc(var(--container-width) / 4), 1fr)
      );
      gap: var(--container-gap);
      & header {
        grid-column: 1 / -1;
        @media (--md-n-above) {
          text-align: center;
          margin-bottom: var(--container-inline-padding);
        }
      }
    }
    &.locations {
      --google-aspect-ratio: 2 / 1;
    }
    &.faq {
      --container-width: 87rem;
      display: grid;
      grid-template-columns: minmax(0, 1fr);
      gap: var(--container-gap);
      align-items: center;
      text-align: center;
      white-space: pre-line;
      & .faq-answer {
        & :deep(p) {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}
</style>
