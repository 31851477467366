<script lang="ts" setup>
const props = defineProps<{
  background: string
}>()

const img = useImage()

const background = computed(() => {
  const imgUrl = img(props.background, { width: 600 })

  return `url('${imgUrl}')`
})
</script>

<template>
  <div class="container">
    <section class="call-to-action">
      <slot></slot>
    </section>
  </div>
</template>

<style lang="postcss" scoped>
@import 'open-props/media';
.container {
  --container-width: 110rem;
  & section.call-to-action {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding: var(--container-gap);
    border-radius: 2.4rem;
    margin-block: var(--container-block-padding);
    background-image: v-bind(background);
    background-repeat: no-repeat;
    background-position: right -200px bottom -250px;
    background-color: var(--background-color-secondary);
    filter: var(--drop-shadow);
    @media (--md-n-below) {
      padding-bottom: 30rem;
      background-size: auto auto;
      background-position: right -200px bottom -350px;
    }
  }
}
</style>
