<script lang="ts" setup>
import { PageSectionResource } from '~/resources/PageResource';
import { useAuthStore } from '~/store/useAuthStore';

const props = defineProps<{
  section: PageSectionResource
}>()
const localePath = useLocalePath()
const store = useAuthStore()

const mark = ref<HTMLElement>()

const handleSubmit = (form: SearchForm) => navigateTo({path: localePath('/spaces'), query: {...form}})

</script>

<template>
  <header class="container">
    <template v-if="store.isAuthenticated && store.user">
      <h1>{{ store.user?.data?.first_name ?? '' }}, {{ section.title.toLowerCase() }}</h1>
    </template>
    <template v-else>
      <h1>{{ section.title }}</h1>
    </template>
    <ul class="usps">
      <li>{{$t('navigation.usp1')}}</li>
      <li>{{$t('navigation.usp2')}}</li>
      <li>{{$t('navigation.usp3')}}</li>
      <li>{{$t('navigation.usp4')}}</li>
    </ul>
  </header>
  <div class="container search-wrapper">
    <ClientOnly fallback-tag="div" fallback="Loading search...">
      <SearchBar @submit="handleSubmit" />
    </ClientOnly>
  </div>
</template>

<style lang="postcss" scoped>
@import 'open-props/media';
header {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  text-align: center;
  z-index: 1;
  & h1 {
    font-size: 7.2rem;
    line-height: 100%;
    font-weight: regular;
    margin-block: 5rem 2rem;
    color: var(--secondary-color, #252525);
    @media (--md-n-below) {
      font-size: 5.6rem;
    }
    & mark {
      display: block;
      color: var(--primary-color, #80dc78);
      background-color: transparent;
    }
  }
  & ul.usps {
    list-style-type: none;
    text-align: left;
    margin: 0 auto;
    padding: 20px 0;
    padding-top: 10px;
    & li {
      font-weight: 700;
      font-size: 1.2em;
      list-style: none;
      background: transparent url("/check-mark.png") no-repeat left center;
      background-size: 28px;
      padding-left: 35px;
      margin-bottom: 10px;
    }
  }
  & ul.pills {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin-bottom: 4rem;
    @media (--md-n-below) {
      flex-direction: column;
    }
    & li {
      padding: 1rem;
      margin: 0 1rem;
      align-self: center;
    }
  }
}
.search-wrapper {
  position: relative;
  z-index: 999;
  --container-width: 70rem;
}
</style>
